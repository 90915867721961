import React from 'react';

export default function Footer(props) {
  return (
    <div className='footer'>
        <span className='footer-links'>
          <a href='/privacy'>privacy</a> |
          <a href='/terms'>terms of service</a> |
          <a href='/contact'>contact us</a> |
          <a href='/plans'>pricing</a>
        </span>
      <span className='float-left'>
          <span className='copyright'>Clientelle © {(new Date().getFullYear())}</span>
          <span className='social-media'>
            <a href='https://twitter.com/clientelle' className='fa fa-twitter'></a> |
            <a href='https://facebook.com/clientelle' className='fa fa-facebook-f'></a>
          </span>
        </span>
    </div>
  )
}
