import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";

export default function Contact(props) {

  return (
    <div className="App">
      <Header />
      <h2>Contact Us</h2>
      <Footer />
    </div>
  )

}
