import React from "react";

export default function SocialLogin(props) {
  return (
    <div className="social-logins">
      <p>Or log in with:</p>
      <a className="fa fa-google" href="/users/auth/google_oauth2"></a>
      <a className="fa fa-instagram" href="/users/auth/instagram"></a>
      <a className="fa fa-facebook-f" href="/users/auth/facebook"></a>
      <a className="fa fa-twitter" href="/users/auth/twitter"></a>
      <a className="fa fa-yahoo" href="/users/auth/yahoo"></a>
    </div>
  );
}
