import React, {useState} from 'react';
import '../styles/signup.css';
import Header from "../components/header";
import Footer from "../components/footer";
import SocialLogin from "../components/social_login";
import serialize from "form-serialize";

import {Link} from "react-router-dom";
import Splash from "../components/splash";

export default function Signup(props) {

  const firebase = props.firebase;

  const [passwordViewMode, setPasswordViewMode] = useState('password');
  const [passwordViewModeIcon, setPasswordViewModeIcon] = useState('fa fa-eye-slash');

  function submitForm(e) {
    e.preventDefault();
    const form = serialize(e.target, { hash: true });

    if (form.user.password !== form.user.password_confirmation) {
      alert("Passwords don't match");
      return false;
    }

    if (form.user.password.length < 5) {
      alert("Passwords can't be less than 5 characters");
      return false;
    }

    if (form.user.email === '') {
      alert("Email cannot be blank");
      return false;
    }

    firebase.auth().createUserWithEmailAndPassword(form.user.email, form.user.password)
      .then((result) => {
        if (result.user.uid) {
          window.location.reload(true);
        }
      })
      .catch(error => {
        console.log(error);
      });

    return false;
  }

  function togglePasswordViewMode() {
    const type = document.getElementById('user_password').type === 'password' ? 'text' : 'password';
    const icon = type === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye';

    setPasswordViewMode(type);
    setPasswordViewModeIcon(icon);
  }

  return (
    <div>
      <Header />
      <div className="row signup">
        <Splash />
        <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 auth-form">
          <h2>Sign up for free</h2>
          <form id="signup-form" action="/signup" acceptCharset="UTF-8" method="post" onSubmit={submitForm}>
            <div className="form-group">
              <input placeholder="Email address" className="form-control" required="required" type="email"
                     name="user[email]" id="user_email" />
            </div>
            <div className="form-group">
              <button type='button' onClick={togglePasswordViewMode} className='see-pwd'>
                <i className={passwordViewModeIcon}></i>
              </button>
              <input autoComplete="off" placeholder="Password" className="form-control" required="required"
                     type={passwordViewMode} name="user[password]" id="user_password" />
            </div>
            <div className="form-group">
              <input autoComplete="off" placeholder="Confirm password" className="form-control" required="required"
                     type={passwordViewMode} name="user[password_confirmation]" id="user_password_confirmation" />
            </div>
            <div>
              <div className="pull-right">
                <small>Have an account? &nbsp; » </small>
                <Link to="/login">Log In</Link>
              </div>
              <button className="btn btn-primary btn-sm" type="submit">Sign up!</button>
            </div>
          </form>
          <SocialLogin />
        </div>
      </div>
      <Footer />
    </div>
  )

}
