import React, {useState} from 'react';
import '../styles/signup.css';
import Header from "../components/header";
import Footer from "../components/footer";
import Splash from "../components/splash";
import SocialLogin from "../components/social_login";

import serialize from "form-serialize";
import {Link} from "react-router-dom";

export default function Login(props) {

  const firebase = props.firebase;

  const [passwordViewMode, setPasswordViewMode] = useState('password');
  const [passwordViewModeIcon, setPasswordViewModeIcon] = useState('fa fa-eye-slash');

  function submitForm(e) {
    e.preventDefault();
    const form = serialize(e.target, {hash: true});

    if (form.user.password.length < 5) {
      alert("Password is incorrect. Please try again.");
      return false;
    }

    firebase.auth().createUserWithEmailAndPassword(form.user.email, form.user.password)
      .catch(error => {
        console.log(error);
      });

    return false;
  }

  function togglePasswordViewMode() {
    const type = document.getElementById('user_password').type === 'password' ? 'text' : 'password';
    const icon = type === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye';

    setPasswordViewMode(type);
    setPasswordViewModeIcon(icon);
  }

  return (
    <div>
      <Header/>
      <div className="row signup">
        <Splash/>
        <div className="col-xs-12 col-sm-5 col-md-4 col-lg-4 auth-form">
          <Link to="/signup" className="pull-right small">Register</Link>
          <h2>Log In</h2>
          <form id="login-form" action="/login" acceptCharset="UTF-8" method="post" onSubmit={submitForm}>
            <div className="form-group">
              <input autoComplete="off" placeholder="Email address" className="form-control" required="required"
                     type="email"
                     name="user[email]" id="user_email"/>
            </div>
            <div className="form-group">
              <button type='button' onClick={togglePasswordViewMode} className='see-pwd'>
                <i className={passwordViewModeIcon}></i>
              </button>
              <input autoComplete="off" placeholder="Password" className="form-control" required="required"
                     type={passwordViewMode} name="user[password]" id="user_password"/>
            </div>
            <button className="btn btn-primary btn-sm" type="submit">Submit</button>
          </form>

          <SocialLogin/>
        </div>
      </div>
      <Footer/>
    </div>
  )

}
