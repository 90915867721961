import React from 'react';

export default function Header(props) {
  return (
    <div className='header'>
      <a href="/" className="header-link">
        <img src="images/app-icon.png" alt="App Icon" className="app-icon" />
        <div className='app-text'>
          <h1 className="heading">Clientelle</h1>
          <span className="tagline">Professional Blackbook</span>
        </div>
      </a>
    </div>
  )
}
