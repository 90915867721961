import React from "react";

export default function Splash(props) {
  return (
    <div className='col-xs-12 col-sm-7 col-md-8 col-lg-8'>
      <p className='blurb'>Clientelle is a private contact manager, business chat and web site widgets.</p>
      <div className='splash-image' />
    </div>
  )
}
