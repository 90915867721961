import React from 'react';
import ReactDOM from 'react-dom';
import './styles/global.css';
import App from './App';

import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const fbase = firebase.initializeApp({
  appId: "1:495091687877:web:45861cdf254c20a418ebac",
  apiKey: "AIzaSyD1WMf4qUElvNSA296nfYJCg7la-4zlyjw",
  authDomain: "clientelle-com-clientelle.firebaseapp.com",
  databaseURL: "https://clientelle-com-clientelle.firebaseio.com",
  projectId: "clientelle.com:clientelle",
  storageBucket: "undefined",
  messagingSenderId: "495091687877"
});

ReactDOM.render(
  <React.StrictMode>
    <App firebase={fbase} />
  </React.StrictMode>,
  document.getElementById('root')
);

