import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";

export default function Home(props) {
  function signOut() {
    props.firebase.auth().signOut()
      .then(() => {
        window.location.reload(true);
      }).catch(error => {
        console.log(error)
      });
  }

  return (
    <div className="App">
      <Header />
      <h2>Home</h2>
      <button onClick={()=> signOut()}></button>
      <Footer />
    </div>
  )

}
