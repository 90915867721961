import React, {useState} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route
} from "react-router-dom";

import Signup from './views/signup';
import Login from './views/login';
import Contact from './views/contact';
import Home from './views/home';

function App(props) {
  const [currentUser, setCurrentUser] = useState(props.firebase.auth().currentUser);

  props.firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setCurrentUser(user);
      console.log('logged in as: ', user);
    } else {
      console.log('logged out');
    }
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => (
          currentUser ? (
            <Home firebase={props.firebase} />
          ) : (
            <Signup firebase={props.firebase} />
          )
        )}/>
        <Route path="/signup">
          <Signup firebase={props.firebase} />
        </Route>
        <Route path="/login">
          <Login firebase={props.firebase} />
        </Route>
        <Route path="/contact">
          <Contact firebase={props.firebase} />
        </Route>
        <Route path="/home">
          <Home firebase={props.firebase} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
